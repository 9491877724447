body {
  background-color: #19191d;
}

.App {
  font-family: sans-serif;
  font-size: 16px;
  color: #efcb7d;
}


.text-run-control {
  margin-top: 8px;
}